<template>

    <!-- BOTONES -->
    <div class="columns mt-3 ml-2 is-multiline is-mobile">

        <div class="column boton-mobil pl-1 ml-2 mr-3" >
            <!-- <router-link @click="verificarHabilitado()" :to="{name: 'AgregarAnunciante'}" > -->
                <button class="button is-info is-outlined p-5" @click="verificarHabilitado('AgregarAnunciante')" :class="{'has-background-info has-text-white' : isMobile}" style="width: 100%">Nuevo Anunciante</button>
            <!-- </router-link> -->
        </div>
        <div class="column boton-mobil mr-4 pl-3 pr-2" >
            <!-- <router-link :to="{name: 'NuevoAnuncio'}"> -->
                <button class="button is-info is-outlined p-5" @click="verificarHabilitado('NuevoAnuncio')" :class="{'has-background-info has-text-white' : isMobile}" style="width: 100%">Nuevo Anuncio</button>
            <!-- </router-link> -->
        </div>
        <div class="column boton-mobil mr-5 pr-0">
            <!-- <router-link :to="{name: 'NuevoPuntoWifi'}"> -->
               <button class="button is-info is-outlined p-5"  @click="verificarHabilitado('NuevoPuntoWifi')" :class="{'has-background-info has-text-white' : isMobile}" style="width: 100%">Nuevo Punto WiFi</button>
            <!-- </router-link> -->
        </div>
    </div>

    <div class="column">

        <div class="columns is-tablet">
            <!-- Tablero de la primera columna -->

            <div class="column ml-4 mr-2 box p-0 box-pequeño" :class="{ 'ml-4' :isMobile}">
                <div class="has-background-grey-lighter pt-2" style="border-radius: 6px 6px 0px 0px">
                    <p class="bd-notification is-info pl-3 text-color-puwic has-text-weight-bold">Total de anuncios habilitados</p>
                </div>
                <hr class="my-0 pt-1" >
                <div class="container mt-2 pt-4">
                    <h1 class="has-text-centered has-text-black tamaño-text">{{totalImpresiones.anunciosHabilitados}}</h1>
                </div>
            </div>

            <div class="column mx-2 box p-0 box-pequeño" :class="{ 'ml-4' :isMobile}">
                <div class="has-background-grey-lighter pt-2" style="border-radius: 6px 6px 0px 0px">
                    <p class="bd-notification is-info pl-3 text-color-puwic has-text-weight-bold">Total impresiones de hoy</p>
                </div>
                <hr class="my-0 pt-1">
                <div class="container has-text-centered mt-2 pt-4">
                    <h1 class="has-text-centered has-text-black tamaño-text">{{totalImpresiones.dia_1_total}}</h1>
                </div>
            </div>

            <div class="column mx-2 box p-0 box-pequeño" :class="{ 'ml-4' :isMobile}">
                <div class="has-background-grey-lighter pt-2" style="border-radius: 6px 6px 0px 0px">
                    <p class="bd-notification is-info pl-3 text-color-puwic has-text-weight-bold">Total impresiones del mes</p>
                </div>
                <hr class="my-0 pt-1">
                <div class="container has-text-centered mt-2 pt-4">
                    <h1 class="has-text-centered has-text-black tamaño-text">{{totalImpresiones.dia_30_total}}</h1>
                </div>
            </div>

            <div class="column ml-2 mb-3 box p-0 box-pequeño" :class="{ 'ml-4 mr-2' :isMobile, 'mr-3':!isMobile}">
                <div class="has-background-grey-lighter pt-2" style="border-radius: 6px 6px 0px 0px">
                    <p class="bd-notification is-info pl-3 text-color-puwic has-text-weight-bold">Total impresiones del año</p>
                </div>
                <hr class="my-0 pt-1">
                <div class="container has-text-centered mt-2 pt-4">
                    <h1 class="has-text-centered has-text-black tamaño-text">{{totalImpresiones.año_total}}</h1>
                </div>
            </div>

        </div>
    </div>

    <div class="column is-full-mobile ml-1" :class="{'py-0': !isMobile}">
        <div class="column box  p-0  modal-scrolleable" style="height:600px">
            <div class="has-background-grey-lighter pt-2" style="border-radius: 6px 6px 0px 0px">
                <p class="bd-notification is-info pl-3 text-color-puwic has-text-weight-bold">Total impresiones</p>
            </div>
            <hr class="my-0 pt-1">

            <div class="column table-container">
                <table class="table" style="width: 100%">
                    <thead>
                        <tr>
                        <th>Punto WiFi</th>
                        <th class="has-text-centered">Impresiones de hoy</th>
                        <th class="has-text-centered">Impresiones del mes</th>
                        <th class="has-text-centered">Impresiones del año</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="impresion in impresiones" :key="impresion.id">
                            <td>{{impresion.nombre}}</td>
                            <td class="has-text-centered">{{impresion.dia_1}}</td>
                            <td class="has-text-centered">{{impresion.dia_30}}</td>
                            <td class="has-text-centered">{{impresion.año}}</td>
                        </tr>
                        <tr>
                        </tr>
                    </tbody>
                </table>

                <div v-show="preload" class="loader-wrapper is-active-loading">
                    <div class="loader is-loading "></div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
import { ref } from '@vue/reactivity'
import store from '@/store'
import { GraphQLClient } from 'graphql-request'
import { inject, watchEffect } from '@vue/runtime-core'
import {queryMe} from '@/composables/queryMe.js'
import { useRouter } from 'vue-router'


export default {

    mounted() {
        setInterval(() => {
            // console.log('actualizo')
            this.traerImpresionestotales()
            this.traerImpresiones()
        },900000)
    },

    setup(){

        const isMobile = inject('isMobile')
        const impresiones = ref([])
        const router = useRouter()

        const totalImpresiones = ref({dia_1_total:0,dia_30_total:0,año_total:0,anunciosHabilitados:0})
        const endpoint = store.state.url_backend
        const anunciosHabilitados = ref(0)
        const preload = ref(true)

        const verificarHabilitado = async (name) => {
            let habilitado = await queryMe()
            if (habilitado) {
                router.push({name: name})
            } else {
                router.push({name: 'Login'})
            }
        }

        const traerImpresiones = () => {
            watchEffect(() => {
                let arreglo = []
                const client = new GraphQLClient(endpoint)

                client.rawRequest(/* GraphQL */ `
                    query{
                        puntowifis(first: 100){
                            paginatorInfo{
                                total
                            }
                            data{
                                id,
                                nombre,
                                impresionesDia,
                                impresionesMes,
                                impresionesAnio
                            }

                        }
                    }`,
                    {

                    },
                    {
                        authorization: `Bearer ${ localStorage.getItem('user_token') }`
                    })
                    .then((data) => {

                        // console.log(data)

                        data.data.puntowifis.data.forEach(element => {
                            arreglo.push({id: element.id, nombre: element.nombre, dia_1: element.impresionesDia, dia_30: element.impresionesMes, año: element.impresionesAnio})
                        })

                        impresiones.value = arreglo

                    }).catch(error => {
                        // console.log(error.response);
                    })
            })

        }

        const traerImpresionestotales = () => {
            const client = new GraphQLClient(endpoint)

            client.rawRequest(/* GraphQL */ `
               query{
                    totaImpresionesMes
                    totalImpresionesDia
                    totalImpresionesAnio
                    totalAnunciosHabilitados

                }`,
                {

                },
                {
                    authorization: `Bearer ${ localStorage.getItem('user_token') }`
                })
                .then((data) => {

                    // console.log(data)

                    totalImpresiones.value.dia_1_total =  data.data.totalImpresionesDia
                    totalImpresiones.value.dia_30_total = data.data.totaImpresionesMes
                    totalImpresiones.value.año_total =  data.data.totalImpresionesAnio
                    totalImpresiones.value.anunciosHabilitados = data.data.totalAnunciosHabilitados

                    preload.value = false

                }).catch(error => {
                    // console.log(error.response);
                })
        }

        return {
            isMobile,
            impresiones ,
            anunciosHabilitados,
            totalImpresiones ,
            traerImpresionestotales,
            traerImpresiones,
            verificarHabilitado,
            preload,
        }

    },

    created () {
        this.traerImpresiones()
        this.traerImpresionestotales()
    }

}

</script>

<style scoped>
@media screen and (max-width: 500px) {
    .boton-mobil{
    text-align: center;
  }
}

@media screen and (min-width: 500px) {
    .button{
    border-style: solid;
    border-width: 3px;

  }
}

.tamaño-text{
    font-size: 40px
}

.text-color-puwic{
    color: #008575
}
.box-pequeño{
    height: 10rem;
}

.fondo-fila{

    background-color: rgb(223, 219, 219);

}

.modal-scrolleable{
  overflow-y: auto;
  overflow-x: hidden ;
  max-height: 600px;
}

@media screen and (max-width: 700px) {
    .modal-scrolleable{
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 370px
  }
}

</style>